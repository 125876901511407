import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Avatar,
  styled,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Alert,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updateEmail, updateProfile } from 'firebase/auth';
import { Appbar, BlackButton, BackButton } from '../../components';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { OnboardingTrainerInfo } from '../../../backend/models';
import { base } from '../../../backend/utils/BaseUrl';
import { firebaseAuth, storage } from '../../../backend/firebase/FirebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, getDownloadURL } from 'firebase/storage';

export const TrainerProfilePageInfo: React.FC = () => {
  const navigate = useNavigate();
  const [trainerInfo, setTrainerInfo] = useState<OnboardingTrainerInfo | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [profileImage, setProfileImage] = useState<string>('');
  const [user, setUser] = useState<any>(null);
  const [showAlert, setShowAlert] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchTrainerInfo(currentUser);
      } else {
        setUser(null);
        setTrainerInfo(null);
        setShowAlert('Please sign in to access your profile');
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchTrainerInfo = async (user: any) => {
    try {
      const uid = user.uid;
      const baseUrl = base;
      const data: any = await firebaseApi.readSingleTrainer(baseUrl, uid);
      console.log(data);
      const profileImageRef = ref(storage, `trainers/${uid}/identificationImage/face`);
      const profileImageURL = await getDownloadURL(profileImageRef);
      setProfileImage(profileImageURL || firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase());

      setTrainerInfo(data);
      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setEmail(user.email || '');
      setGender(data.gender || '');
      setPhoneNumber(data.phoneNumber || '');
    } catch (error) {
      console.error('Error fetching trainer info:', error);
      setAlertSeverity('error');
      setShowAlert('Failed to load profile information. Please refresh the page.');
    }
  };

  const reauthenticate = async (currentPassword: string) => {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log('Current user ID:', user?.uid);
    console.log('Current user email:', user?.email);

    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      try {
        await reauthenticateWithCredential(user, credential);
        console.log('Reauthentication successful');
      } catch (error: any) {
        console.error('Error reauthenticating:', error);
        setAlertSeverity('error');
        setShowAlert(error.code === 'auth/wrong-password' ? 'Incorrect password' : 'Authentication failed');
        throw error;
      }
    }
  };

  const handleSave = async () => {
    console.log('Save button clicked');
    const auth = getAuth();
    const user = auth.currentUser;
    console.log('Current user:', user);
    console.log('Current user email:', user?.email);

    if (!user) {
      setShowAlert('Please sign in to save changes');
      setAlertSeverity('error');
      return;
    }

    if (email !== user.email) {
      const currentPassword = prompt('Please enter your current password to update the email:');

      if (currentPassword) {
        try {
          await reauthenticate(currentPassword);
          await updateEmail(user, email);
          setAlertSeverity('success');
          setShowAlert('Email updated successfully');
        } catch (error) {
          setAlertSeverity('error');
          console.error('Error updating email:', error);
          return;
        }
      } else {
        setAlertSeverity('error');
        setShowAlert('Password required to update email');
        return;
      }
    }

    try {
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });
      console.log('User profile updated successfully');

      const phoneString = String(phoneNumber);
      const updatedInfo: OnboardingTrainerInfo = {
        phoneNumber: phoneString,
        gender,
      };

      await firebaseApi.updateSingleTrainer(base, user.uid, updatedInfo).then((response: any) => {
        if (response.error) {
          throw new Error(response.error || 'Failed to update profile');
        }
        setAlertSeverity('success');
        setShowAlert('Profile updated successfully');
      });

      setAlertSeverity('success');
      setShowAlert('Profile updated successfully');
    } catch (error) {
      console.error('Failed to update profile:', error);
      setShowAlert('Failed to update profile. Please try again.');
      setAlertSeverity('error');
    }
  };

  const handleGenderChange = (event: SelectChangeEvent<string>) => {
    setGender(event.target.value as string);
  };

  const handleBackClick = () => {
    navigate('/trainer-profile');
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      <Box display='flex' justifyContent='center' alignItems='center'>
        <ProfileContainer>
          <Avatar
            alt='Profile Picture'
            src={profileImage}
            sx={{ width: '112px', height: '100px', marginBottom: '5%' }}
          />

          <LabelTypography>First Name</LabelTypography>
          <StyledTextFieldUnchanged variant='filled' value={firstName} inputProps={{ readOnly: true }} />

          <LabelTypography>Last Name</LabelTypography>
          <StyledTextFieldUnchanged variant='filled' value={lastName} inputProps={{ readOnly: true }} />

          <LabelTypography>Email</LabelTypography>
          <StyledTextField variant='filled' value={email} onChange={(e) => setEmail(e.target.value)} />

          <LabelTypography>Phone Number</LabelTypography>
          <StyledTextField variant='filled' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

          <LabelTypography>Gender</LabelTypography>
          <StyledFormControl>
            <StyledSelect
              value={gender}
              onChange={(e) => handleGenderChange(e as React.ChangeEvent<HTMLInputElement>)}
              inputProps={{ 'aria-label': 'Gender' }}
              sx={{ borderRadius: '15px', marginBottom: '7%' }}
              IconComponent={(props) => <ArrowDropDownIcon {...props} style={{ color: 'black' }} />}
              MenuProps={{
                sx: {
                  width: '100%',
                },
              }}
            >
              <MenuItem disabled value=''>
                <em>How do you identify?</em>
              </MenuItem>
              <MenuItem value='Male'>Male</MenuItem>
              <MenuItem value='Female'>Female</MenuItem>
              <MenuItem value='Non-binary'>Non-binary</MenuItem>
              <MenuItem value='Other'>Other</MenuItem>
            </StyledSelect>
          </StyledFormControl>
          <Collapse in={!!showAlert}>
            <Alert
              severity={alertSeverity}
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{
                mb: 2,
                mx: 2,
              }}
            >
              {showAlert}
            </Alert>
          </Collapse>
          <BlackButton text='Save' handleClick={handleSave}></BlackButton>
        </ProfileContainer>
      </Box>
    </>
  );
};

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '4%',
  marginBottom: '-1%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
    marginBottom: '-3%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
    marginBottom: '-3%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
    marginBottom: '-2%',
  },
}));

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  width: '80%',
}));

const StyledTextFieldUnchanged = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    padding: '15px 20px',
    lineHeight: '1.5',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  '& .MuiFilledInput-input': {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    display: 'none',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    padding: '15px 20px',
    lineHeight: '1.5',
  },
  '& .MuiFilledInput-input': {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    display: 'none',
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  width: '100%',
  borderRadius: '15px',
  marginTop: '4%',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#333',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '10%',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    marginTop: '6%',
  },
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginBottom: '1%',
  fontWeight: 'bold',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  lineHeight: 1.5,
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
}));
