import React, { useEffect, useState, useRef } from 'react';
import {
  styled,
  Box,
  Typography,
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  Alert,
  Collapse,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton, GradientButton } from '../../components';
import { ArrowForwardIos as ForwardIcon, Close as CloseIcon } from '@mui/icons-material';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { DateTime } from 'luxon';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const font = createTheme({
  typography: {
    fontFamily: 'Oswald, sans-serif',
  },
});

// for converting what clients have paid to what trainer will receive per session
function getTrainerResIncome(sessionCost: string): string {
  switch (sessionCost) {
    case '25':
      return '21';
    case '48':
      return '22';
    case '69':
      return '23';
    case '88':
      return '24';
    case '21':
      return '14.5';
    case '40':
      return '15.5';
    case '57':
      return '16.5';
    case '72':
      return '17.5';
    case '17':
      return '9';
    case '32':
      return '9.5';
    case '45':
      return '10.5';
    case '56':
      return '11';
    case '12':
      return '7.5';
    case '22':
      return '8';
    case '30':
      return '8.5';
    case '36':
      return '9';
    default:
      throw new Error('Invalid session cost provided.');
  }
}

export const TrainerReservationRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const { formatPrice } = usePriceFormatter();

  const [requestData, setRequestData] = useState<
    {
      id: string;
      clientName: string;
      clientID: string;
      sessionLength: number;
      sessionType: string;
      acceptTimeLeft: string;
      income: string;
      profilePhoto: string;
      cost: string;
      requestedSessions: [];
    }[]
  >([]);
  const uid = useSelector((state: RootState) => state.user.uid);
  const [showAlert, setShowAlert] = useState<string | null>(null);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(850));
  const [centerVertically, setCenterVertically] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const handleBackClick = () => {
    navigate(-1);
  };

  const calculateAcceptTimeLeft = (createdAt: string) => {
    if (!createdAt) return 'Unknown';

    const createdTime = DateTime.fromISO(createdAt, { zone: 'utc' });
    const now = DateTime.now();

    const minutesElapsed = now.diff(createdTime, 'minutes').minutes;
    const minutesLeft = Math.max(0, 48 * 60 - minutesElapsed);

    if (minutesLeft < 60) {
      return `${Math.floor(minutesLeft)} Minutes`;
    } else {
      const hoursLeft = Math.floor(minutesLeft / 60);
      const remainingMinutes = Math.floor(minutesLeft % 60);
      return `${hoursLeft} Hours ${remainingMinutes} Minutes`;
    }
  };

  useEffect(() => {
    const fetchReservationRequests = async () => {
      if (!uid) {
        console.warn('No user id found');
        setShowAlert('Trainer ID is missing');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await myApi.getSessionRequestsByTrainerID(base, uid);
        console.log('Fetched Requests: ', response);

        if ((response as any).error === 'No pending session requests found for the given trainer ID') {
          setError('No Pending Request');
          setLoading(false);
          return;
        }

        if (!Array.isArray(response)) {
          setShowAlert('Unexpected response format');
          setLoading(false);
          return;
        }

        // fetch client info
        const requestsWithClientNames = await Promise.all(
          response.map(async (request: any) => {
            const clientResponse: any = await myApi.readSingleClient(base, request.clientID);
            const clientName = clientResponse?.firstName
              ? `${clientResponse.firstName} ${clientResponse.lastName?.charAt(0) || ''}.`
              : 'Unknown Client';
            const profilePhoto =
              clientResponse?.profilePhoto ||
              clientResponse.firstName.charAt(0).toUpperCase() + clientResponse.lastName.charAt(0).toUpperCase();

            const sessionArray = request.requestedSessions || request.sessionInfo || [];
            const sessionLength = sessionArray.length;

            return {
              id: request.id,
              clientID: request.clientID,
              clientName,
              profilePhoto,
              sessionLength: sessionLength,
              sessionType: request.sessionType || '',
              acceptTimeLeft: calculateAcceptTimeLeft(request.createdAt || ''),
              income: getTrainerResIncome(request.cost || 0) || 0,
              cost: request.cost,
              requestedSessions: sessionArray,
            };
          })
        );

        // sort by time left
        requestsWithClientNames.sort((a, b) => {
          const timeA = a.acceptTimeLeft.split(' ');
          const timeB = b.acceptTimeLeft.split(' ');

          const minutesA =
            timeA[1] === 'Hours' ? parseInt(timeA[0]) * 60 + (parseInt(timeA[2]) || 0) : parseInt(timeA[0]);
          const minutesB =
            timeB[1] === 'Hours' ? parseInt(timeB[0]) * 60 + (parseInt(timeB[2]) || 0) : parseInt(timeB[0]);
          return minutesA - minutesB;
        });

        setRequestData(
          requestsWithClientNames.map((request) => ({
            id: request.id.toString(),
            clientName: request.clientName,
            clientID: request.clientID,
            profilePhoto: request.profilePhoto.toString(),
            sessionLength: Number(request.sessionLength),
            sessionType: request.sessionType.toString(),
            acceptTimeLeft: request.acceptTimeLeft.toString(),
            income: request.income.toString(),
            cost: request.cost.toString(),
            requestedSessions: request.requestedSessions || [],
          }))
        );
        setError('');
      } catch (error) {
        console.error('Error fetching reservation requests: ', error);
        setShowAlert('Error fetching requests');
      } finally {
        setLoading(false);
      }
    };
    fetchReservationRequests();
  }, [uid]);

  useEffect(() => {
    if (isDesktop && cardRef.current && containerRef.current) {
      const cardHeight = cardRef.current.offsetHeight;
      const containerHeight = containerRef.current.offsetHeight;
      const cardsPerPage = Math.floor(containerHeight / cardHeight);
      setCenterVertically(requestData.length <= cardsPerPage);
    }
  }, [isDesktop, requestData.length]);

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Typography
          sx={{
            textAlign: 'center',
            mt: '1.5rem',
            color: '#666',
            fontFamily: 'Oswald, sans-serif',
            fontSize: '1.2rem',
          }}
        >
          {error}
        </Typography>
      );
    }

    return requestData.map((request) => (
      <Card
        key={request.id}
        ref={cardRef}
        sx={{
          mb: '2rem',
          borderRadius: '1rem',
          backgroundColor: '#e8e8e8',
          marginInline: 'auto',
          width: '90%',
          maxWidth: '1200px',
          boxShadow: '0px 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
          '@media (min-width: 600px)': {
            width: '85%',
          },
          '@media (min-width: 850px)': {
            width: '90%',
          },
          '@media (min-width: 1200px)': {
            width: '80%',
          },
          '@media (min-width: 1500px)': {
            width: '70%',
          },
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            pb: '1rem',
            px: { xs: '1rem', sm: '2rem' }, // Responsive padding
          }}
        >
          <Typography
            sx={{
              color: '#F27C22',
              fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' }, // Responsive font size
              fontWeight: 600,
              mb: '1.5rem',
              textAlign: 'center',
            }}
          >
            {request.sessionLength} Session(s) with {request.clientName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile
              alignItems: 'center',
              mb: '1rem',
              gap: '1rem',
              ml: { lg: '1rem' },
            }}
          >
            <Avatar
              alt={`Session with ${request.clientName}`}
              src={request.profilePhoto}
              sx={{
                width: { xs: '4rem', sm: '6rem', md: '8rem' }, // Responsive size
                height: { xs: '4rem', sm: '6rem', md: '8rem' },
                mr: { xs: 0, sm: '1.5rem' },
                mb: { lg: '2rem' },
              }}
            >
              {request.profilePhoto}
            </Avatar>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
                width: '100%',
              }}
            >
              {/* Left side - Earnings */}
              <Box
                sx={{
                  marginLeft: { lg: -3 },
                  marginTop: { xs: 2, lg: 0 },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '1rem',
                    mb: '0.5rem',
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  Total Earnings:
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '1.5rem', sm: '1.875rem', md: '2rem' }, // Responsive font size
                    fontWeight: 'bold',
                    mb: '1rem',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(parseFloat(request.income) * request.sessionLength)}
                </Typography>
              </Box>
              {/* Right side - Session details */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                  alignItems: { xs: 'center', sm: 'flex-start' }, // Center on mobile
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <Box
                    component='span'
                    sx={{
                      marginTop: { lg: -3 },
                      display: 'inline-flex',
                      width: '1.25rem',
                      height: '1.25rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FitnessCenterIcon sx={{ fontSize: '1.25rem' }} />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: { lg: -3 },
                      color: '#444',
                      fontWeight: 800,
                      fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, // Responsive font size
                    }}
                  >
                    Session Length: {request.sessionType}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <Box
                    component='span'
                    sx={{
                      display: 'inline-flex',
                      width: '1.25rem',
                      height: '1.25rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <WatchLaterIcon
                      sx={{
                        color: 'black',
                        fontSize: '1.25rem',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: '#444',
                      fontWeight: 800,
                      fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, // Responsive font size
                    }}
                  >
                    Time Left to Accept: {request.acceptTimeLeft}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <GradientButton
            text='See Reservation Details'
            showArrowIcon={true}
            handleClick={() =>
              navigate('/trainer-reservation-details', {
                state: {
                  request: {
                    id: request.id,
                    clientName: request.clientName,
                    clientID: request.clientID,
                    profilePhoto: request.profilePhoto,
                    sessionLength: request.sessionLength,
                    sessionType: request.sessionType,
                    acceptTimeLeft: request.acceptTimeLeft,
                    income: request.income,
                    cost: request.cost,
                    sessions: request.requestedSessions,
                  },
                },
              })
            }
          />
        </CardContent>
      </Card>
    ));
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      {isDesktop ? (
        <Grid container sx={{ height: 'calc(100vh - 68px)' }}>
          <Grid item md={5} sx={{ height: '100%', overflow: 'hidden' }}>
            <Box
              sx={{
                '@media (min-width:1024px) and (orientation: landscape)': {
                  marginTop: 'calc(38vh - 50px)',
                  paddingLeft: '15%',
                  height: '100%',
                },
              }}
            >
              <Title sx={{ fontWeight: '550' }}>Review Reservation Requests</Title>
              <Description>
                Review reservation requests from clients and accept them within 48 hours. Make sure to check the total
                pay, date, and time of the requested session. Note that the total amount is before any potential tips
                from clients.
              </Description>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} sx={{ minHeight: '100%', overflow: 'auto', overflowX: 'hidden' }}>
            <Box
              ref={containerRef}
              sx={{
                marginTop: '5%',
                height: '90%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555',
                },
              }}
            >
              <Collapse in={!!showAlert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{
                    mb: 2,
                    mx: 2,
                    '@media (min-width:1024px) and (orientation: landscape)': {
                      mx: '15%',
                    },
                  }}
                >
                  {showAlert}
                </Alert>
              </Collapse>
              {renderContent()}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ position: 'relative', paddingTop: { xs: 0 } }}>
          <Title>Review Reservation Requests</Title>
          <Description>
            Review reservation requests from clients and accept them within 48 hours. Make sure to check the total pay,
            date, and time of the requested session. Note that the total amount is before any potential tips from
            clients.
          </Description>
          <Collapse in={!!showAlert}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{
                mb: 2,
                mx: 2,
                '@media (min-width:1024px) and (orientation: landscape)': {
                  mx: '15%',
                },
              }}
            >
              {showAlert}
            </Alert>
          </Collapse>
          {renderContent()}
        </Box>
      )}
    </>
  );
};

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '2.5%',
  marginBottom: '0.5%',
  padding: '0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#F27C22',
  fontFamily: 'Oswald, sans-serif',
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.6rem',
    marginTop: '17%',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: '13px',
  lineHeight: '18px',
  textAlign: 'center',
  maxWidth: '90%',
  margin: '0 auto 30px',
  padding: '0 15px',
  fontFamily: 'Oswald, sans-serif',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    lineHeight: '22px',
  },
}));
