import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import myApi from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { VideoPage } from './VideoPage';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import moment from 'moment-timezone';
import { Appbar } from '../../components';

interface VideoData {
  clientToken: string;
  trainerToken: string;
  callingID: string;
  clientUserId: string;
  trainerUserId: string;
  time: string;
  clientID: string;
  trainerID: string;
  dateTime: string;
}

export const VideoPageWrapper: React.FC = () => {
  const { sessionID } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const validateSessionTime = (dateTime: string) => {
      const sessionTime = moment(dateTime);
      const currentTime = moment();
      const earliestJoinTime = moment(sessionTime).subtract(5, 'minutes');
      const latestJoinTime = moment(sessionTime).add(10, 'minutes');

      if (currentTime.isBefore(earliestJoinTime)) {
        throw new Error('It is too early to join this session. Please join within 5 minutes of the scheduled time.');
      }

      if (currentTime.isAfter(latestJoinTime)) {
        throw new Error('This session has expired. You can only join within 10 minutes after the scheduled time.');
      }
    };

    const initializeVideoCall = async () => {
      try {
        // Try to get data from sessionStorage first
        let existingSessionId = sessionStorage.getItem('sessionID');
        console.log('session id: ', existingSessionId);
        console.log('session id: ', sessionID);

        if (!existingSessionId) {
          existingSessionId = sessionID || null;
        }

        if (!existingSessionId) {
          throw new Error('No session ID found');
        }

        // Otherwise fetch fresh data
        const response = await myApi.getVideoData(base, existingSessionId!);

        if (typeof response === 'string' || (response as any).error) {
          throw new Error(typeof response === 'string' ? response : (response as any).error);
        }

        const videoData = response as VideoData;
        validateSessionTime(videoData.dateTime);

        // Store data in sessionStorage
        const agent = sessionStorage.getItem('Agent');
        if (!agent) {
          throw new Error('Please login to join the video call');
        }
        const isTrainer = agent === 'trainer';
        sessionStorage.setItem('sessionID', existingSessionId!);
        sessionStorage.setItem('callId', videoData.callingID);
        sessionStorage.setItem('token', isTrainer ? videoData.trainerToken : videoData.clientToken);
        sessionStorage.setItem('userId', isTrainer ? videoData.trainerUserId : videoData.clientUserId);
        if (videoData.time) sessionStorage.setItem('time', videoData.time);
        sessionStorage.removeItem('status');

        // Verify required fields after storing
        const hasRequiredFields = ['sessionID', 'callId', 'token', 'userId'].every((field) =>
          sessionStorage.getItem(field)
        );

        if (!hasRequiredFields) {
          throw new Error('Missing required data to join the video call');
        }
      } catch (err) {
        console.error('Error fetching video data:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch video data');
      } finally {
        setLoading(false);
      }
    };

    initializeVideoCall();
  }, [sessionID]);

  const handleBackClick = () => {
    navigate('/trainer-upcoming-sessions');
  };

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' minHeight='100vh'>
          <Appbar showMenu={true} />
          <Typography variant='h6' color='error' marginBottom={2}>
            {error}
          </Typography>
          <Button variant='contained' color='primary' onClick={handleBackClick}>
            Go Back to Upcoming Sessions
          </Button>
        </Box>
      </>
    );
  }

  return <VideoPage />;
};
