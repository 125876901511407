import React from 'react';
import { styled, Box, Typography, Divider, ListItemText, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BlackButton, BackButton } from '../../components';
import {
  QueryBuilder as QueryBuilderIcon,
  FitnessCenter as FitnessCenterIcon,
  CreditCard as CreditCardIcon,
  Discount as DiscountIcon,
} from '@mui/icons-material';

export const ReserveInfoPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isLandscapeLaptop = useMediaQuery('(min-width:1024px) and (orientation: landscape)');

  const { trainerID, trainerName } = location.state || {
    trainerID: sessionStorage.getItem('trainerID'),
    trainerName: sessionStorage.getItem('trainerName'),
  };

  console.log('trainerID: ', trainerID);
  console.log('trainerName: ', trainerName);

  if (!trainerID || !trainerName) {
    console.error('Trainer information is missing');
    return <Typography>Error: Trainer information is not available</Typography>;
  }

  const firstName = trainerName.split(' ')[0];

  const handleButtonClick = () => {
    navigate('/reserve-choice', { state: { trainerID, trainerName } });
  };

  const handleBackClick = () => {
    navigate('/client-profile');
  };

  return (
    <PageContainer>
      <Appbar showMenu={false} showCloseIcon={false} userRole='client' />
      <StyledBackButton onClick={handleBackClick} />
      <Grid container spacing={0}>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <ReserveImage src='../../../../reserve.jpg' alt='Gyfr Reserve' />
        </Grid>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <ContentContainer>
            <Title sx={{ fontFamily: 'Oswald, sans-serif' }} variant='h3'>
              Gyfr Reserve
            </Title>
            <InformationContainer>
              <InfoBox>
                <IconBox>
                  <QueryBuilderIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText primary='Choose your session timings in advance.' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <FitnessCenterIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText primary='Book with the same trainer multiple times and stay in touch with them through chat.' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <CreditCardIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText primary='Cancel at no charge up to 24 hours in advance.' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <DiscountIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText primary='All sessions are free for Gyfr Plus Members!' />
              </InfoBox>
            </InformationContainer>
            <Box sx={{ flexGrow: 1 }} />
            <ButtonContainer>
              <BlackButton text={`Reserve session(s) with ${firstName}`} handleClick={handleButtonClick}></BlackButton>
            </ButtonContainer>
          </ContentContainer>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginBottom: '1%',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '17%',
    width: '80%',
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  '@media (min-width:1024px) and (orientation: landscape)': {
    overflow: 'hidden',
  },
}));

const ReserveImage = styled('img')(({ theme }) => ({
  marginTop: '6px',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginTop: '10px',
    width: '55vw',
    height: '100vh',
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  '@media (min-width:1024px) and (orientation: landscape)': {
    height: '100vh',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  color: '#F27C22',
  marginBottom: '20px',
  fontWeight: 'bold',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '18%',
    marginTop: '13%',
  },
}));

const InformationContainer = styled(Box)(({ theme }) => ({
  marginBottom: '30px',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '15%',
    width: '80%',
  },
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '13px 0',
}));

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '3%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
  },
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  width: '40px',
  height: '40px',
};

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: 17,
  textAlign: 'left',
  fontWeight: 'bold',
}));

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: 20,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    top: 70,
    left: 15,
  },
  [theme.breakpoints.down('sm')]: {
    top: 65,
    left: 0,
  },
  // Add specific handling for very small screens
  '@media (max-width: 380px)': {
    top: 60,
    left: -5,
  },
}));
