import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

interface AppbarProps {
  showMenu: boolean;
  showCloseIcon?: boolean;
  userRole?: 'client' | 'trainer';
}

export const Appbar: React.FC<AppbarProps> = ({ showMenu, showCloseIcon, userRole }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = () => {
    localStorage.setItem('previousRoute', location.pathname);
    console.log(localStorage.getItem('previousRoute'));
    navigate('/drawbar');
  };

  const handleClose = () => {
    const previousRoute = localStorage.getItem('previousRoute');
    if (previousRoute) {
      navigate(previousRoute);
      localStorage.removeItem('previousRoute');
    } else {
      location.pathname.includes('trainer') ? navigate('/trainer-home') : navigate('/home-page');
    }
  };

  const handleProfileClick = () => {
    localStorage.setItem('previousRoute', location.pathname);
    console.log(localStorage.getItem('previousRoute'));
    if (userRole === 'client') {
      navigate('/client-profile');
    } else if (userRole === 'trainer') {
      navigate('/trainer-profile');
    }
  };

  return (
    <div style={{ marginBottom: 50 }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='fixed' sx={{ backgroundColor: 'black' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src='../../../../gyfr_logo.png' alt='Gyfr Logo' style={{ marginTop: 1.5, height: 25 }} />

            {showMenu && !showCloseIcon && (
              <IconButton
                size='large'
                edge='end'
                color='inherit'
                aria-label='menu'
                sx={{ mr: '1%' }}
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
            )}
            {!showMenu && (
              <IconButton
                size='large'
                edge='end'
                color='inherit'
                aria-label='profile'
                sx={{ mr: '1%' }}
                onClick={handleProfileClick}
              >
                <PersonIcon />
              </IconButton>
            )}
            {showCloseIcon && (
              <IconButton
                size='large'
                edge='end'
                color='inherit'
                aria-label='close'
                sx={{ mr: '1%' }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Appbar;
