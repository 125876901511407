import React, { useEffect, useState } from 'react';
import { styled, Box, Typography, Divider, Button, Grid, useMediaQuery, Snackbar, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BlackButton } from '../../components';
import {
  QueryBuilder as QueryBuilderIcon,
  CreditCard as CreditCardIcon,
  Mail as MailIcon,
  Cancel as CancelIcon,
  Forum as ForumIcon,
  ArrowForwardIos as ForwardIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';

export const ClientConfirmationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLandscapeLaptop = useMediaQuery('(min-width:1024px) and (orientation: landscape)');
  const trainerName = useSelector((state: RootState) => state.sessionRequest.trainerName);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showChatMessage, setShowChatMessage] = useState(false);

  const sendNotifications = async (requestInfo: any) => {
    const { clientID, trainerID, sessionType, sessionInfo, cost } = requestInfo;
    const notificationDetails = {
      clientID,
      trainerID,
      sessionType,
      cost,
      sessions: sessionInfo,
    };

    try {
      // Send client notification
      const clientResponse = await myApi.sendEmailNotification(base, {
        recipientID: clientID,
        type: 'pending',
        details: notificationDetails,
      });

      if ('error' in clientResponse) {
        throw new Error(`Client notification failed: ${clientResponse.error}`);
      }
      console.log(`Client notification successful: ${notificationDetails}`);
      // Send trainer notification
      const trainerResponse = await myApi.sendEmailNotification(base, {
        recipientID: trainerID,
        type: 'pending',
        details: notificationDetails,
      });

      if ('error' in trainerResponse) {
        throw new Error(`Trainer notification failed: ${trainerResponse.error}`);
      }
      console.log(`Trainer notification successful: ${notificationDetails}`);
      return true;
    } catch (error) {
      console.error('Error sending notifications:', error);
      throw error;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    console.log('Session ID:', sessionId);

    if (!sessionId) {
      console.log('No session ID found. Unable to finalize your request.');
      setErrorMessage('No session ID found. Unable to finalize your request.');
      return;
    }

    myApi
      .finalizeSessionRequest(base, sessionId)
      .then(async (response: any) => {
        if (response.error) {
          console.log('Error finalizing session request:', response.error);
          setErrorMessage(`Error finalizing session request: ${response.error}`);
        } else if (response.message === 'Session request finalized successfully') {
          console.log(`Session request finalized successfully: ${response.requestInfo}`);
          setErrorMessage('Your session request is now pending trainer approval.');
          // Send notifications
          await sendNotifications(response.requestInfo);
        } else {
          console.log('Unexpected response while finalizing:', response);
          setErrorMessage('Unexpected response while finalizing. Please try again later.');
        }
      })
      .catch((err: any) => {
        console.error('Error finalizing session request:', err);
        setErrorMessage('Error finalizing session request. Please try again later.');
      });
  }, [location]);

  const handleButtonClick = () => {
    setShowChatMessage(true);
  };

  return (
    <PageContainer>
      <Appbar showMenu={true} showCloseIcon={true} userRole='trainer' />
      <Grid container spacing={0}>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <ReserveImage src='../../../../confirmation.jpg' alt='Gyfr Reserve' />
        </Grid>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <ContentContainer>
            <Title variant='h3'>Next Steps</Title>
            <InformationContainer>
              <InfoBox>
                <IconBox>
                  <QueryBuilderIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='Your reservation is pending. Your trainer will confirm it within next 48 hours. ' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <CreditCardIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='You won’t be charged until your trainer confirms your reserved sessions. ' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <CancelIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='If you wish to reschedule your sessions, contact us at least 24 hours before your session time. ' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <ForumIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='You can now text your trainer to stay in touch, discuss your progress, set nutritional goals, and ask any questions!' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <MailIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='Once confirmed, we will email you the video call link for your session(s). You can also access them through your Gyfr Profile Page. ' />
              </InfoBox>
            </InformationContainer>
            <Box sx={{ flexGrow: 1 }} />
            <Box alignContent={'center'} display='flex' justifyContent='center'>
              <ButtonContainer>
                <BlackButton text={`Text ${trainerName}`} handleClick={handleButtonClick}></BlackButton>
              </ButtonContainer>
            </Box>
            <Snackbar
              open={showChatMessage}
              autoHideDuration={4000}
              onClose={() => setShowChatMessage(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={() => setShowChatMessage(false)} severity='info' sx={{ width: '100%' }}>
                The chat functionality is not yet available, but it will be released very soon. Thank you for your
                patience as we work on building this feature!
              </Alert>
            </Snackbar>
          </ContentContainer>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '13%',
    width: '80%',
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  '@media (min-width:1024px) and (orientation: landscape)': {
    overflow: 'hidden',
  },
}));

const ReserveImage = styled('img')(({ theme }) => ({
  marginTop: '6px',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginTop: '10px',
    width: '55vw',
    height: '100vh',
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  '@media (min-width:1024px) and (orientation: landscape)': {
    height: '100vh',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  color: '#F27C22',
  marginBottom: '20px',
  fontWeight: 'bold',
  fontFamily: 'Oswald, sans-serif',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginBottom: '2%',
    marginLeft: '17.5%',
    marginTop: '8vh',
  },
}));

const InformationContainer = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '15%',
    width: '80%',
  },
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '13px 0',
}));

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '3%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
  },
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  width: '40px',
  height: '40px',
};

const CustomListItemText = styled(Typography)(({ theme }) => ({
  marginLeft: 17,
  textAlign: 'left',
  fontSize: '15px',
  lineHeight: '120%',
}));

const IconSpan = styled('span')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px',
});
