import React, { useState, useEffect, useMemo } from 'react';
import {
  styled,
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Alert,
  Collapse,
  IconButton,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton } from '../../components';
import { Search as SearchIcon, Star as StarIcon, Close as CloseIcon } from '@mui/icons-material';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingTrainerInfo } from '../../../backend/models';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';

export const SearchTrainerPage: React.FC = () => {
  const [trainers, setTrainers] = useState<OnboardingTrainerInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');
  const navigate = useNavigate();
  const isLandscapeLaptop = useMediaQuery('(min-width:1024px) and (orientation: landscape)');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    fetchTrainers();
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    // Clear any previous search-related errors
    if (showAlert.includes('search')) {
      setShowAlert('');
    }
  };

  const handleTrainerClick = (trainerID: string, trainerName: string) => {
    try {
      sessionStorage.setItem('trainerID', trainerID);
      sessionStorage.setItem('trainerName', trainerName);
      navigate('/client-reserve', { state: { trainerID, trainerName, source: 'searchPage' } });
    } catch (error) {
      setShowAlert('Unable to save trainer selection. Please try again.');
      setAlertSeverity('error');
    }
  };

  const getLastInitial = (lastName: string): string => {
    return lastName ? lastName.charAt(0).toUpperCase() + '.' : '';
  };

  const fetchTrainers = async () => {
    setLoading(true);
    try {
      const response = await myApi.readAllTrainers(base);
      if (typeof response === 'string' || (response as any).error) {
        throw new Error(typeof response === 'string' ? response : (response as any).error);
      }

      if (!Array.isArray(response)) {
        throw new Error('Invalid response format from server');
      }

      const trainersData: OnboardingTrainerInfo[] = (response as any[]).map((trainer) => ({
        trainerID: trainer.trainerID,
        ...trainer.data,
        firstName: trainer.data?.firstName || '',
        lastInitial: getLastInitial(trainer.data?.lastName || ''),
        rating: trainer.data?.rating || 0,
        UserPhoto: trainer.data?.UserPhoto || 'default_avatar_url',
      }));

      if (trainersData.length === 0) {
        setShowAlert('No trainers are currently available.');
        setAlertSeverity('error');
      } else {
        setTrainers(trainersData);
        setShowAlert('');
      }
    } catch (err: any) {
      setShowAlert(err.message || 'Failed to fetch trainers. Please try again.');
      setAlertSeverity('error');
      console.error('Error fetching trainers:', err);
    } finally {
      setLoading(false);
    }
  };

  const filteredTrainers = useMemo(() => {
    try {
      return trainers
        .filter(
          (trainer) =>
            `${trainer.firstName || ''} ${trainer.lastName || ''}`.toLowerCase().includes(searchText.toLowerCase()) ||
            trainer.trainerID?.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort((a, b) => {
          const matchA = `${a.firstName || ''} ${a.lastName || ''}`.toLowerCase().indexOf(searchText.toLowerCase());
          const matchB = `${b.firstName || ''} ${b.lastName || ''}`.toLowerCase().indexOf(searchText.toLowerCase());
          if (matchA === matchB) return (b.rating || 0) - (a.rating || 0);
          return matchA - matchB;
        });
    } catch (error) {
      console.error('Error filtering trainers:', error);
      setShowAlert('Error filtering search results. Please try again.');
      setAlertSeverity('error');
      return [];
    }
  }, [trainers, searchText]);

  const topPicks = useMemo(() => {
    try {
      return [...trainers].sort((a, b) => (b.rating || 0) - (a.rating || 0)).slice(0, 4);
    } catch (error) {
      console.error('Error calculating top picks:', error);
      return [];
    }
  }, [trainers]);

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      {isLandscapeLaptop ? (
        <Grid
          container
          sx={{
            height: 'calc(100vh - 50px)',
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Grid
            item
            md={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              margin: 0,
              padding: 0,
              background: 'linear-gradient(0deg, #8C1414 0%, #F27C22 100%)',
            }}
          >
            <Box sx={{ textAlign: 'center', width: '100%', maxWidth: '500px', padding: '20px' }}>
              <Collapse in={!!showAlert}>
                <Alert
                  severity={alertSeverity}
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{
                    mb: 2,
                    mx: 2,
                    '@media (min-width:1024px)': {
                      mx: '15%',
                    },
                  }}
                >
                  {showAlert}
                </Alert>
              </Collapse>
              <Title sx={{ fontWeight: '550', mt: -7 }}>Find A Trainer</Title>
              <Subtitle>Already have someone in mind? Find them below and schedule a session!</Subtitle>
              <SearchContainer>
                <StyledSearchIcon />
                <StyledInput
                  sx={{ color: '#000000', opacity: '1' }}
                  placeholder="Enter a Trainer's Name or their Trainer ID"
                  value={searchText}
                  onChange={handleInputChange}
                  inputProps={{ 'aria-label': 'search trainer' }}
                />
              </SearchContainer>
              {searchText.length > 0 ? (
                filteredTrainers.length > 0 ? (
                  <Dropdown>
                    <List>
                      {filteredTrainers.map((trainer) => (
                        <ListItem
                          key={trainer.trainerID}
                          onClick={() =>
                            handleTrainerClick(
                              trainer.trainerID || '',
                              `${trainer.firstName || ''} ${getLastInitial(trainer.lastName || '')}`
                            )
                          }
                        >
                          <Avatar
                            src={trainer.UserPhoto || 'default_avatar_url'}
                            alt={trainer.firstName || 'Trainer'}
                            sx={{ marginRight: 2, width: 70, height: 61 }}
                          />
                          <ListItemText
                            primary={`${trainer.firstName || 'Trainer'} ${getLastInitial(trainer.lastName || '')}`}
                            secondary={
                              <Box sx={{ display: 'flex', alignItems: 'center', color: 'black', marginLeft: -0.5 }}>
                                <StarIcon fontSize='small' sx={{ marginRight: '4px' }} />
                                {(trainer.rating || 0).toFixed(2)}
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Dropdown>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                        borderRadius: '10px',
                        backgroundColor: '#E0E0E0',
                        padding: '2rem 2rem',
                      }}
                    >
                      <Typography> Oops, no trainers matching your criteria were found.</Typography>
                    </Paper>
                  </Box>
                )
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} sx={{ height: '100%', overflow: 'auto' }}>
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
              <Typography variant='h6' sx={{ fontWeight: 'bold', mt: -2, mb: 5 }}>
                Our Top Picks
              </Typography>
              <Grid container spacing={2} justifyContent='center' alignItems='center'>
                {topPicks.map((trainer) => (
                  <Grid item xs={6} md={5} key={trainer.trainerID} sx={{ textAlign: 'center' }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Avatar
                        src={trainer.UserPhoto || 'default_avatar_url'}
                        alt={trainer.firstName || 'Trainer'}
                        sx={{ width: 80, height: 80, margin: 'auto' }}
                      />
                      <Typography sx={{ fontWeight: 'bold', mt: 1, ml: 0.5 }}>
                        {`${trainer.firstName || ''} ${getLastInitial(trainer.lastName || '')}`}
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        <StarIcon fontSize='inherit' sx={{ marginRight: '4px' }} />
                        {(trainer.rating || 0).toFixed(2)}
                      </Box>
                      <Typography
                        variant='body2'
                        sx={{ color: '#F27C22', fontWeight: 'bold', mt: 1, cursor: 'pointer' }}
                        onClick={() =>
                          handleTrainerClick(
                            trainer.trainerID || '',
                            `${trainer.firstName || ''} ${getLastInitial(trainer.lastName || '')}`
                          )
                        }
                      >
                        Train →
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: { xs: 2, sm: '60px' },
            }}
          >
            <Collapse in={!!showAlert}>
              <Alert
                severity={alertSeverity}
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{
                  mb: 2,
                  mx: 2,
                  '@media (min-width:1024px)': {
                    mx: '15%',
                  },
                }}
              >
                {showAlert}
              </Alert>
            </Collapse>
            <Title>Find A Trainer</Title>
            <Subtitle>Already have someone in mind? Find them below and schedule a session!</Subtitle>
            <SearchContainer>
              <StyledSearchIcon />
              <StyledInput
                sx={{ color: '#000000', fontWeight: '550', opacity: '1' }}
                placeholder="Enter a Trainer's Name or their Trainer ID"
                value={searchText}
                onChange={handleInputChange}
                inputProps={{ 'aria-label': 'search trainer' }}
              />
            </SearchContainer>
            {searchText.length > 0 ? (
              filteredTrainers.length > 0 ? (
                <Dropdown>
                  <List>
                    {filteredTrainers.map((trainer) => (
                      <ListItem
                        key={trainer.trainerID}
                        onClick={() =>
                          handleTrainerClick(
                            trainer.trainerID || '',
                            `${trainer.firstName || ''} ${getLastInitial(trainer.lastName || '')}`
                          )
                        }
                      >
                        <Avatar
                          src={trainer.UserPhoto || 'default_avatar_url'}
                          alt={trainer.firstName || 'Trainer'}
                          sx={{ width: '70px', height: '61px', marginRight: 2 }}
                        />
                        <ListItemText
                          primary={`${trainer.firstName || 'Trainer'} ${getLastInitial(trainer.lastName || '')}`}
                          secondary={
                            <Box sx={{ display: 'flex', alignItems: 'center', color: 'black', marginLeft: -0.5 }}>
                              <StarIcon fontSize='small' sx={{ marginRight: '4px' }} />
                              {(trainer.rating || 0).toFixed(2)}
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Dropdown>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Paper
                    sx={{
                      mt: 2,
                      p: 1,
                      borderRadius: '10px',
                      backgroundColor: '#E0E0E0',
                      padding: '1rem 1rem',
                      maxWidth: '85%',
                    }}
                  >
                    <Typography> Oops, no trainers matching your criteria were found.</Typography>
                  </Paper>
                </Box>
              )
            ) : null}
          </Box>
          <Box sx={{ textAlign: 'center', marginTop: '15%' }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>
              Our Top Picks
            </Typography>
            <Grid container spacing={2} mt={2} justifyContent='center'>
              {topPicks.map((trainer, index) => (
                <Grid item xs={6} md={3} key={index}>
                  <Box
                    onClick={() =>
                      handleTrainerClick(
                        trainer.trainerID || '',
                        `${trainer.firstName || ''} ${getLastInitial(trainer.lastName || '')}`
                      )
                    }
                    sx={{ cursor: 'pointer', textAlign: 'center' }}
                  >
                    <Avatar
                      src={trainer.UserPhoto || 'default_avatar_url'}
                      alt={trainer.firstName || 'Trainer'}
                      sx={{ width: 109, height: 109, margin: 'auto' }}
                    />
                    <Typography sx={{ fontWeight: 'bold', mt: 1, ml: 0.5 }}>
                      {`${trainer.firstName || ''} ${getLastInitial(trainer.lastName || '')}`}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      <StarIcon fontSize='inherit' sx={{ marginRight: '4px' }} />
                      {(trainer.rating || 0).toFixed(2)}
                    </Box>
                    <Typography variant='body2' sx={{ color: '#F27C22', fontWeight: 'bold', mb: 2 }}>
                      Train →
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: 5,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    marginTop: '4%',
    top: 50,
    left: 5,
    zIndex: 1000,
  },
  [theme.breakpoints.down('sm')]: {
    position: 'static',
    marginTop: '4%',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#000000',
  fontFamily: 'Oswald, sans-serif',
  marginBottom: '5px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.6rem',
    marginBottom: '0px',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  textAlign: 'center',
  fontFamily: 'Oswald, sans-serif',
  letterSpacing: '0.05px',
  color: '#656565',
  marginBottom: '20px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
    color: '#222222',
    marginBottom: '35px',
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#D9D9D9',
  borderRadius: '25px',
  padding: '8px 16px',
  position: 'relative',
  flexGrow: 1,
  maxWidth: '500px',
  width: '90%',
  boxSizing: 'border-box',
  marginLeft: '5%',
  [theme.breakpoints.down('lg')]: {
    padding: '8px 12px',
    marginLeft: 0,
  },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: '#F27C22',
  position: 'relative',
  pointerEvents: 'none',
  marginRight: theme.spacing(1),
  left: -3,
  [theme.breakpoints.down('sm')]: {
    left: 0,
  },
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  fontSize: '15px',
  color: '#F27C22',
  '&::placeholder': {
    color: '#F27C22',
    opacity: 1,
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const Dropdown = styled(Box)(({ theme }) => ({
  backgroundColor: '#E0E0E0',
  borderRadius: '15px',
  marginTop: theme.spacing(1),
  maxHeight: '300px',
  overflowY: 'auto',
  width: '90%',
  marginLeft: '5%',
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
  },
}));
