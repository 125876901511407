import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Button,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Divider,
  Alert,
  Snackbar,
} from '@mui/material';
import {
  PermIdentity as YourInfoIcon,
  SupportAgent as SupportIcon,
  Psychology as PsychologyIcon,
  History as HistoryIcon,
  CreditCard as CreditCardIcon,
  Diversity3 as FriendsIcon,
  Star as StarIcon,
  ForumRounded as ForumRoundedIcon,
  AccessTimeFilled as AccessTimeFilledIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Event as EventIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Appbar } from '../../components';
import { firebaseAuth } from '../../../backend/firebase';
import { useDispatch } from 'react-redux';
import { setUid } from '../../../backend/redux/store';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../backend/firebase/FirebaseConfig';

export const TrainerProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [generalError, setGeneralError] = useState<string>('');
  const [firstName, setFirstName] = useState('Sam');
  const [rating, setRating] = useState(0);
  const [profileImage, setProfileImage] = useState<string>('');

  const [showChatMessage, setShowChatMessage] = useState(false);

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleSignOutButton = async () => {
    try {
      await signOut(firebaseAuth);
      dispatch(setUid(null));
      navigate('/trainer-login');

      // Optionally close the window if sign-out is part of a popup
      if (window.opener) {
        window.close();
      }
    } catch (error) {
      setGeneralError('An unexpected error occurred. Please try again later.');
      console.log(error);
      navigate('/trainer-login');
    }
    navigate('/');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        fetchTrainerInfo(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchTrainerInfo = async (user: any) => {
    try {
      const uid = user.uid;
      const data: any = await firebaseApi.readSingleTrainer(base, uid);
      console.log(data);
      const profileImageRef = ref(storage, `trainers/${uid}/identificationImage/face`);
      const profileImageURL = await getDownloadURL(profileImageRef);
      setProfileImage(profileImageURL || 'https://example.com/path/to/profile/picture.jpg');

      setFirstName(data.firstName || '');
      setRating(data.rating || 0.0);
    } catch (error) {
      console.error('Error fetching trainer info:', error);
    }
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <ProfileContainer>
        <ProfileHeader>
          <ProfileInfo>
            <Typography
              variant='h4'
              component='h1'
              sx={{ fontFamily: 'Oswald, sans-serif', textTransform: 'uppercase', fontWeight: 'bold' }}
            >
              {firstName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <StarIcon sx={{ color: 'black', marginRight: '5px' }} />
              <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='h6' component='h2'>
                {rating.toFixed(2)}
              </Typography>
            </Box>
          </ProfileInfo>
          <Avatar alt='Profile Picture' src={profileImage} sx={{ width: '112px', height: '100px', marginRight: -1 }} />
        </ProfileHeader>
        {/* TODO: Add navigation route within App */}
        <FullWidthButton
          onClick={() => navigate('/trainer-availability')}
          sx={{ marginTop: '20px', backgroundColor: '#F27C22' }}
        >
          <FullWidthButtonTextContainer>
            <FullWidthButtonTitle variant='subtitle1'>Set Your Availability</FullWidthButtonTitle>
            <FullWidthButtonSubtitle variant='caption'>
              Pre-booked sessions are compensated at a higher rate.
            </FullWidthButtonSubtitle>
          </FullWidthButtonTextContainer>
          <EventIcon sx={{ marginLeft: '20px', fontSize: '60px', color: 'white' }} />
        </FullWidthButton>

        <FullWidthButton
          onClick={() => navigate('/trainer-reservation')}
          sx={{ marginTop: '20px', backgroundColor: '#F27C22' }}
        >
          <FullWidthButtonTextContainer>
            <FullWidthButtonTitle variant='subtitle1'>Review Reservation Requests</FullWidthButtonTitle>
            <FullWidthButtonSubtitle variant='caption'>
              Once a client requests a booking, you have 48 hours to confirm.
            </FullWidthButtonSubtitle>
          </FullWidthButtonTextContainer>
          <CheckCircleIcon sx={{ marginLeft: '20px', fontSize: '60px', color: 'white' }} />
        </FullWidthButton>

        <ButtonRow>
          <CustomButton onClick={() => navigate('/trainer-upcoming-sessions')}>
            <AccessTimeFilledIcon />
            <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Upcoming Sessions</Typography>
          </CustomButton>

          <CustomButton onClick={() => navigate('/trainer-payout')}>
            <CreditCardIcon />
            <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Your Earnings</Typography>
          </CustomButton>

          <CustomButton onClick={() => setShowChatMessage(true)}>
            <ForumRoundedIcon />
            <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Chat with your Clients</Typography>
          </CustomButton>
        </ButtonRow>
        <FullWidthButton
          onClick={() => handleExternalLink('https://gyfrapp.com/pages/affiliate-marketing')}
          sx={{ marginTop: '20px' }}
        >
          <FullWidthButtonTextContainer>
            <FullWidthButtonTitle variant='subtitle1'>Become an affiliate and earn!</FullWidthButtonTitle>
            <FullWidthButtonSubtitle variant='caption'>
              Earn 10% commission on every session booked through your referral!
            </FullWidthButtonSubtitle>
          </FullWidthButtonTextContainer>
          <FriendsIcon sx={{ marginLeft: '20px', fontSize: '60px' }} />
        </FullWidthButton>
      </ProfileContainer>
      {/* TODO: Add navigation hyperlink */}
      <ListItemButtonStyled onClick={() => navigate('/trainer-info')}>
        <IconBox sx={iconStyles}>
          <YourInfoIcon />
        </IconBox>
        <CustomListItemText primary='Your Information' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => navigate('/trainer-support')}>
        <IconBox sx={iconStyles}>
          <SupportIcon />
        </IconBox>
        <CustomListItemText primary='Gyfr Support' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/personal-trainer-guide')}>
        <IconBox sx={iconStyles}>
          <PsychologyIcon />
        </IconBox>
        <CustomListItemText primary='Learning Center' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => navigate('/trainer-sessions')}>
        <IconBox sx={iconStyles}>
          <HistoryIcon />
        </IconBox>
        <CustomListItemText primary='Previous Sessions' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <ProfileContainer>
        <CustomButton variant='contained' onClick={handleSignOutButton} sx={{ width: '90%', textTransform: 'none' }}>
          Sign Out
        </CustomButton>
        <Snackbar
          open={showChatMessage}
          autoHideDuration={4000}
          onClose={() => setShowChatMessage(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setShowChatMessage(false)} severity='info' sx={{ width: '100%' }}>
            The chat functionality is not yet available, but it will be released very soon. Thank you for your patience
            as we work on building this feature!
          </Alert>
        </Snackbar>
      </ProfileContainer>
    </>
  );
};

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
  padding: '20px',
  width: '100%',
  maxWidth: '90%',
  margin: '0 auto',
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '85%',
  justifyContent: 'space-between',
  marginTop: '15px',
}));

const ProfileInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ButtonRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '20px',
  gap: '20px',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    width: '100%',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  flex: '1', // equal space
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
}));

const FullWidthButton = styled(Button)(({ theme }) => ({
  width: '100%', // Ensure full width alignment
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  padding: '10px',
  textAlign: 'left',
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const FullWidthButtonTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const FullWidthButtonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
  },
}));

const FullWidthButtonSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
  },
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '10px',
  textAlign: 'left',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
};

const IconBox = styled(Box)(({ theme }) => ({
  marginLeft: '7%',
}));

const ArrowIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  marginRight: '5%',
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: '15px 0',
}));
