import React, { useState, useEffect } from 'react';
import { styled, Box, Typography, Button, Alert, Collapse, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BlackButton, BackButton } from '../../components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { TimeConflictUtils } from '../../../backend/utils/TimeValidation';
import { useDispatch } from 'react-redux';
import { setTrainerName } from '../../../backend/redux/sessionRequestSlice';

export const ReviewTimingPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { trainerID, trainerName, sessions, cost } = location.state || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const clientID = useSelector((state: any) => state.user.uid);
  const [showAlert, setShowAlert] = useState<string>('');
  const [isValidating, setIsValidating] = useState<boolean>(false);

  useEffect(() => {
    // Initial validation when component mounts
    validateTimeSlots();
  }, []);

  const validateTimeSlots = async () => {
    if (!trainerID || !sessions) {
      setShowAlert('Invalid session data');
      return false;
    }

    try {
      // Fetch trainer data to get timezone
      const trainerResponse = await myApi.readSingleTrainer(base, trainerID);
      if (typeof trainerResponse === 'string' || (trainerResponse as any).error) {
        throw new Error('Failed to fetch trainer information');
      }
      const trainerData = trainerResponse as any;

      // Validate all sessions for time conflicts
      const validationResult = await TimeConflictUtils.validateSessionRequest(
        trainerID,
        sessions.map((session: any) => ({
          date: session.date,
          timeRange: session.timeRange,
          sessionType: session.sessionType || '15 minute',
        })),
        trainerData.timezone || 'UTC'
      );

      if (!validationResult.valid) {
        setShowAlert(validationResult.error || 'A time conflict was detected. Please choose different time slots.');
        return false;
      }

      return true;
    } catch (error) {
      setShowAlert('Failed to validate time slots. Please try again.');
      return false;
    }
  };

  const handleConfirm = async () => {
    setIsValidating(true);
    try {
      const isValid = await validateTimeSlots();
      if (!isValid) {
        return;
      }

      const timeRanges = sessions.map((session: any) => ({
        startTime: moment(session.timeRange.startTime, ['HH:mm', 'hh:mm A']).format('hh:mm A'),
        endTime: moment(session.timeRange.endTime, ['HH:mm', 'hh:mm A']).format('hh:mm A'),
      }));
      const selectedDates = sessions.map((session: any) => session.date);
      const sessionType = sessions[0]?.sessionType;

      const requestBody = {
        clientID,
        trainerID,
        sessionType,
        selectedDates,
        selectedTimeRanges: timeRanges,
        cost,
      };

      const response = await myApi.createCheckoutSession(base, requestBody);

      if ('error' in response) {
        setShowAlert(response.error as string);
        return;
      }

      dispatch(setTrainerName(trainerName));

      if ('checkoutUrl' in response) {
        window.location.href = response.checkoutUrl as string;
      } else {
        throw new Error('Unexpected API response');
      }
    } catch (err) {
      console.error('Error confirming sessions:', err);
      setShowAlert('Error confirming sessions. Please try again later.');
    } finally {
      setIsValidating(false);
    }
  };

  const handleBackClick = () => {
    navigate('/choose-timing');
  };

  return (
    <PageWrapper>
      <ScrollContainer>
        <Appbar showMenu={false} showCloseIcon={false} userRole='client' />
        <StyledBackButton onClick={handleBackClick} />
        <Container>
          <Title sx={{ fontFamily: 'Oswald, sans-serf' }} variant={isMobile ? 'h5' : 'h4'}>
            Review Your Chosen Timings
          </Title>
          <SessionsContainer>
            {sessions && sessions.length > 0 ? (
              sessions.map((session: any, index: number) => {
                const formattedTime = moment(session.timeRange.startTime, 'hh:mm A').format('hh:mm A');
                const formattedDate = moment(session.date, 'YYYY-MM-DD').format('MM/DD/YYYY');

                return (
                  <SessionCard key={index}>
                    <SessionInfo>{`${session.sessionType.split(' ')[0]} minute session on ${formattedDate} at ${formattedTime} EST`}</SessionInfo>
                    <TrainerInfo>Trainer: {trainerName}</TrainerInfo>
                  </SessionCard>
                );
              })
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Typography>No sessions available to review.</Typography>
              </Box>
            )}
          </SessionsContainer>
        </Container>
      </ScrollContainer>
      <ButtonWrapper>
        <Box
          sx={{
            marginLeft: { lg: 4 },
            width: { xs: '95%', lg: '50%' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'transparent',
          }}
        >
          <Collapse in={!!showAlert}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ marginTop: 2 }}
            >
              {showAlert}
            </Alert>
          </Collapse>
          <BlackButton text='Confirm and Go To Pay' handleClick={handleConfirm} />
        </Box>
      </ButtonWrapper>
    </PageWrapper>
  );
};

const PageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  maxWidth: '100vw',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'transparent',
});

const ScrollContainer = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  height: '100%',
  backgroundColor: 'transparent',
});

const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  padding: '16px 0',
  zIndex: 1000,
  backgroundColor: 'transparent',
  [theme.breakpoints.up('lg')]: {
    position: 'fixed',
    bottom: 0,
    marginTop: 0,
    backgroundColor: 'transparent',
  },
}));

const Container = styled(Box)(({ theme }) => ({
  marginTop: '9vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '15px 10px',
    paddingBottom: '90px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: '#F27C22',
  fontWeight: 'bold',
  marginBottom: '20px',
  fontSize: 'clamp(1.25rem, 4vw, 2rem)',
  lineHeight: '1.2',
  textAlign: 'center',
  marginTop: '1%',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: '30px',
    marginRight: '16px',
  },
  '@media (max-width: 380px)': {
    marginTop: '6vh',
  },
}));

const SessionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
});

const SessionCard = styled(Box)(({ theme }) => ({
  width: '80%',
  maxWidth: '450px',
  margin: '0 auto',
  padding: '15px',
  backgroundColor: '#F5F5F5',
  borderRadius: '15px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 15,
    top: '10%',
    bottom: '10%',
    width: '2px',
    backgroundColor: '#000000',
    borderRadius: '2px',
  },
  paddingLeft: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '93%',
    maxWidth: 'none',
    padding: '12px',
    paddingLeft: '16px',
    margin: '0 -10px',
  },
}));

const SessionInfo = styled(Typography)(({ theme }) => ({
  marginLeft: 10,
  fontWeight: 'bold',
  fontSize: '1rem',
  marginBottom: '4px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const TrainerInfo = styled(Typography)(({ theme }) => ({
  marginLeft: 10,
  color: '#666',
  fontSize: '0.9rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
}));

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: 20,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    top: 70,
    left: 15,
  },
  [theme.breakpoints.down('sm')]: {
    top: 65,
    left: 10,
  },
  '@media (max-width: 380px)': {
    top: 60,
    left: -5,
  },
}));
