import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Avatar,
  styled,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Alert,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updateProfile,
  Auth,
} from 'firebase/auth';
import { Appbar, BlackButton, BackButton } from '../../components';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { OnboardingClientInfo } from '../../../backend/models';
import { base } from '../../../backend/utils/BaseUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { firebaseAuth } from '../../../backend/firebase/FirebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL, deleteObject, StorageReference } from 'firebase/storage';
// import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { storage } from '../../../backend/firebase/FirebaseConfig';

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '4%',
  marginBottom: '-1%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
    marginBottom: '-3%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
    marginBottom: '-3%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
    marginBottom: '-2%',
  },
}));

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  width: '80%',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    padding: '15px 20px',
    lineHeight: '1.5',
  },
  '& .MuiFilledInput-input': {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    display: 'none',
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  width: '100%',
  borderRadius: '15px',
  marginTop: '4%',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#333',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '10%',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    marginTop: '6%',
  },
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginBottom: '1%',
  fontWeight: 'bold',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  lineHeight: 1.5,
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
}));

export const ProfilePageInfo: React.FC = () => {
  const navigate = useNavigate();
  const [clientInfo, setClientInfo] = useState<OnboardingClientInfo | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uid = useSelector((state: RootState) => state.user.uid);
  const [user, setUser] = useState<any>({} as any);
  const [showAlert, setShowAlert] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');

  const getUserEmail = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      console.log('User email:', user.email);
      return user.email;
    } else {
      console.log('No user is signed in');
      return null;
    }
  };

  const getUserPhoneNumber = async (): Promise<string> => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const uid = user.uid;
      const baseUrl = base;
      const data = (await firebaseApi.readSingleClient(baseUrl, uid)) as OnboardingClientInfo;
      return data.phoneNumber || '';
    } else {
      console.log('No user is signed in');
      return '';
    }
  };

  const getUserName = async (): Promise<{ firstName: string; lastName: string }> => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const uid = user.uid;
      const baseUrl = base;
      const data = (await firebaseApi.readSingleClient(baseUrl, uid)) as OnboardingClientInfo;
      return { firstName: data.firstName || '', lastName: data.lastName || '' };
    } else {
      console.log('No user is signed in');
      return { firstName: '', lastName: '' };
    }
  };
  //   const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
  //     setUser(currentUser);
  //     console.log(currentUser);
  //  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchClientInfo(currentUser);
      } else {
        setUser(null);
        setClientInfo(null);
        setShowAlert('Please sign in to access your profile');
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchClientInfo = async (user: any) => {
    try {
      const uid = user.uid;
      const baseUrl = base;
      const data: any = await firebaseApi.readSingleClient(baseUrl, uid);
      console.log(data);

      const profileImageRef = ref(storage, `clients/${uid}/profilePhoto`);
      let profileImageURL;

      try {
        profileImageURL = await getDownloadURL(profileImageRef);
      } catch (imageError) {
        console.warn('Profile photo not found, using default image:', imageError);
        profileImageURL = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
      }

      setProfileImage(profileImageURL);
      setClientInfo(data);
      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setEmail(user.email || '');
      setGender(data.gender || '');
      setPhoneNumber(data.phoneNumber || '');
    } catch (error) {
      console.error('Error fetching client info:', error);
      setShowAlert('Failed to load profile information. Please refresh the page.');
      setAlertSeverity('error');
    }
  };

  const reauthenticate = async (currentPassword: string) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      try {
        await reauthenticateWithCredential(user, credential);
        console.log('Reauthentication successful');
      } catch (error: any) {
        console.error('Error reauthenticating:', error);
        setAlertSeverity('error');
        setShowAlert(error.code === 'auth/wrong-password' ? 'Incorrect password' : 'Authentication failed');
        throw error;
      }
    }
  };

  const handleSave = async () => {
    console.log('Save button clicked');
    const auth = getAuth();
    const user = auth.currentUser;
    console.log('Current user:', user);
    console.log('Current user email:', user?.email);

    if (!user) {
      setShowAlert('Please sign in to save changes');
      setAlertSeverity('error');
      return;
    }

    // Update user profile
    try {
      if (email !== user.email) {
        const currentPassword = prompt('Please enter your current password to update the email:');

        if (currentPassword) {
          try {
            await reauthenticate(currentPassword);
            await updateEmail(user, email);
            console.log('Email updated successfully');
            setAlertSeverity('success');
            setShowAlert('Email updated successfully');
          } catch (error) {
            console.error('Error updating email:', error);
            setAlertSeverity('error');
            setShowAlert('Error updating email');
            return;
          }
        } else {
          setShowAlert('Password required to update email');
          setAlertSeverity('error');
          return;
        }
      }

      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });

      console.log('Updated Phone number:', phoneNumber, typeof phoneNumber);
      const phoneString = String(phoneNumber);

      // Update client info
      const updatedInfo: OnboardingClientInfo = {
        firstName,
        lastName,
        phoneNumber: phoneString,
        gender,
        profilePhoto: profileImage,
      };
      await firebaseApi.updateSingleClient(base, user.uid, updatedInfo).then((response: any) => {
        if (response.error) {
          throw new Error(response.error || 'Failed to update profile');
        }
        setAlertSeverity('success');
        setShowAlert('Profile updated successfully');
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      setShowAlert('Failed to update profile. Please try again.');
      setAlertSeverity('error');
    }
  };

  const handleGenderChange = (event: SelectChangeEvent<string>) => {
    setGender(event.target.value as string);
  };

  const handleBackClick = () => {
    navigate('/client-profile');
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const storageRef = ref(storage, `clients/${uid}/profilePhoto`);
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        setProfileImage(downloadURL);
        reader.readAsDataURL(file);
      } catch (error) {
        console.log('Error uploading image: ', error);
        setAlertSeverity('error');
      }
    }
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      <Box display='flex' justifyContent='center' alignItems='center'>
        <ProfileContainer>
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept='image/*'
            onChange={handleFileChange}
          />
          <Avatar
            alt='Profile Picture'
            src={profileImage}
            sx={{ width: '112px', height: '100px', cursor: 'pointer' }}
            onClick={handleAvatarClick}
          />
          <Typography
            variant='body2'
            sx={{ marginTop: '2%', marginBottom: '5%', cursor: 'pointer', fontWeight: 'bold', fontSize: '15px' }}
            onClick={handleAvatarClick}
          >
            Upload Profile Picture
          </Typography>

          <LabelTypography>First Name</LabelTypography>
          <StyledTextField variant='filled' value={firstName} onChange={(e) => setFirstName(e.target.value)} />

          <LabelTypography>Last Name</LabelTypography>
          <StyledTextField variant='filled' value={lastName} onChange={(e) => setLastName(e.target.value)} />

          <LabelTypography>Email</LabelTypography>
          <StyledTextField variant='filled' value={email} onChange={(e) => setEmail(e.target.value)} />

          <LabelTypography>Phone Number</LabelTypography>
          <StyledTextField variant='filled' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

          <LabelTypography>Gender</LabelTypography>
          <StyledFormControl>
            <StyledSelect
              value={gender}
              onChange={(e) => handleGenderChange(e as React.ChangeEvent<HTMLInputElement>)}
              inputProps={{ 'aria-label': 'Gender' }}
              sx={{ borderRadius: '15px', marginBottom: '7%' }}
              IconComponent={(props) => <ArrowDropDownIcon {...props} style={{ color: 'black' }} />}
              MenuProps={{
                sx: {
                  width: '100%',
                },
              }}
            >
              <MenuItem disabled value=''>
                <em>How do you identify?</em>
              </MenuItem>
              <MenuItem value='Male'>Male</MenuItem>
              <MenuItem value='Female'>Female</MenuItem>
              <MenuItem value='Non-binary'>Non-binary</MenuItem>
              <MenuItem value='Other'>Other</MenuItem>
            </StyledSelect>
          </StyledFormControl>
          <Collapse in={!!showAlert}>
            <Alert
              severity={alertSeverity}
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{
                mb: 2,
                mx: 2,
              }}
            >
              {showAlert}
            </Alert>
          </Collapse>
          <BlackButton text='Save' handleClick={handleSave}></BlackButton>
        </ProfileContainer>
      </Box>
    </>
  );
};
