import React, { useEffect, useState } from 'react';
import {
  styled,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Button,
  Divider,
  SelectChangeEvent,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BlackButton, BackButton } from '../../components';
import { ArrowDropDown as ArrowDropDownIcon, Style } from '@mui/icons-material';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';

export const ReserveChoicePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trainerID, trainerName } = location.state || {
    trainerID: sessionStorage.getItem('trainerID'),
    trainerName: sessionStorage.getItem('trainerName'),
  };
  const [numberOfSessions, setNumberOfSessions] = useState<number>(1);
  const [typeOfSessions, setTypeOfSessions] = useState<string>('15');
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const { formatPrice } = usePriceFormatter();

  const priceMap = new Map([
    ['100', 30],
    ['60', 25],
    ['45', 21],
    ['30', 17],
    ['15', 12],
    ['0', 0],
  ]);

  const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
    const selectedSessions = event.target.value as string;
    setTypeOfSessions(selectedSessions);
    sessionStorage.setItem('typeOfSessions', selectedSessions);
  };

  const handleNumberChange = (event: SelectChangeEvent<unknown>) => {
    const selectedSessions = event.target.value as number;
    setNumberOfSessions(selectedSessions);
  };

  const handleButtonClick = () => {
    navigate('/choose-timing', {
      state: { trainerID, trainerName, sessionType: typeOfSessions + ' minutes', cost: finalPrice, numberOfSessions },
    });
  };

  const handleBackClick = () => {
    navigate('/client-reserve');
  };

  useEffect(() => {
    const pricePerSession = priceMap.get(typeOfSessions) as number;
    const finalPrice = numberOfSessions * (pricePerSession - (numberOfSessions - 1));
    const originalPrice = pricePerSession * numberOfSessions;
    const newDiscount = originalPrice - finalPrice;
    setTotalPrice(originalPrice);
    setDiscount(newDiscount);
    setFinalPrice(finalPrice);
  }, [typeOfSessions, numberOfSessions]);

  return (
    <>
      <Appbar showMenu={false} showCloseIcon={false} userRole='client' />
      <StyledBackButton onClick={handleBackClick} />
      <Container>
        <Title sx={{ fontFamily: 'Oswald, sans-serif' }} variant='h4'>
          Book Session with {trainerName}
        </Title>

        <LabelTypography>What session type would you like to reserve?</LabelTypography>
        <DropDown>
          <StyledSelect
            value={typeOfSessions}
            onChange={handleTypeChange}
            IconComponent={(props) => <ArrowDropDownIcon {...props} style={{ color: 'black', fontSize: '30px' }} />}
          >
            <MenuItem value={'15'}>15 Minute Online Session</MenuItem>
            <MenuItem value={'30'}>30 Minute Online Session</MenuItem>
            <MenuItem value={'45'}>45 Minute Online Session</MenuItem>
            <MenuItem value={'60'}>60 Minute Online Session</MenuItem>
          </StyledSelect>
        </DropDown>

        <LabelTypography>How many sessions would you like to book?</LabelTypography>
        <DropDown>
          <StyledSelect
            value={numberOfSessions}
            onChange={handleNumberChange}
            IconComponent={(props) => <ArrowDropDownIcon {...props} style={{ color: 'black', fontSize: '30px' }} />}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </StyledSelect>
        </DropDown>

        <NoteText sx={{ fontWeight: 'bold' }} variant='body2'>
          Note: If you're a Gyfr Plus Member, all your sessions are free and included in your membership! Simply enter
          your membership code during checkout.
        </NoteText>

        <ExpectedPriceDetails>Expected Price Details</ExpectedPriceDetails>
        <PriceDetail>
          <PriceLabelContainer>
            <PriceLabel>Session Fee x {numberOfSessions} Sessions</PriceLabel>
            <PriceNote>{formatPrice(priceMap.get(typeOfSessions) as number)} per session</PriceNote>
          </PriceLabelContainer>
          <PriceAmount>{formatPrice(totalPrice)}</PriceAmount>
        </PriceDetail>
        <PriceDetail>
          <PriceLabelContainer>
            <PriceLabel>Bulk Discount</PriceLabel>
            <PriceNote>Discount for reserving multiple sessions</PriceNote>
          </PriceLabelContainer>
          <PriceAmount>-{formatPrice(discount)}</PriceAmount>
        </PriceDetail>

        <Divider />

        <TotalPrice>
          <PriceLabel>Total Expected Price:</PriceLabel>
          <PriceAmount>{formatPrice(finalPrice)}</PriceAmount>
        </TotalPrice>

        <BlackButton text='Choose Date and Time' handleClick={handleButtonClick}></BlackButton>
      </Container>
    </>
  );
};

const Container = styled(Box)(({ theme }) => ({
  padding: '2%',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: '25px',
  color: '#F27C22',
  marginBottom: '50px',
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.7rem',
    marginTop: '7vh',
  },
  '@media (max-width: 380px)': {
    marginTop: '7vh', // Significantly more space for very small screens
  },
}));

const DropDown = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: '30px',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  lineHeight: 1.5,
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
  fontSize: '15px',
  marginBottom: '10px',
  fontWeight: 'bold',
  marginLeft: '0.5%',
}));

const NoteText = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  color: '#F27C22',
  fontSize: '15px',
  marginLeft: '0.5%',
  marginRight: '0.5%',
  marginBottom: '40px',
  lineHeight: '1.2',
}));

const ExpectedPriceDetails = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: '20px',
  marginLeft: '0.5%',
}));

const PriceDetail = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1%',
  marginLeft: '0.5%',
  alignItems: 'center',
}));

const PriceLabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  marginBottom: '15px',
}));

const PriceLabel = styled(Typography)(({ theme }) => ({
  color: '#363535',
  fontSize: '17px',
}));

const PriceNote = styled(Typography)(({ theme }) => ({
  color: '#868686',
  fontSize: '12px',
  marginLeft: '0.5%',
}));

const PriceAmount = styled(Typography)(({ theme }) => ({
  marginRight: '0.5%',
  fontSize: '19px',
}));

const TotalPrice = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  marginLeft: '0.5%',
}));

const ReserveButton = styled(Button)(({ theme }) => ({
  marginTop: '20px',
  backgroundColor: '#000000',
  width: '100%',
  fontSize: '20px',
  color: '#FFFFFF',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#333333',
  },
}));

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: 20,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    top: 70,
    left: 15,
  },
  [theme.breakpoints.down('sm')]: {
    top: 65,
    left: 0,
  },
  // Add specific handling for very small screens
  '@media (max-width: 380px)': {
    top: 60,
    left: -5,
  },
}));
