import React, { CSSProperties, useEffect, useState } from 'react';
import { Typography, Box, Button, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Appbar, Customcard, Map } from '../../components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BackHandIcon from '@mui/icons-material/BackHand';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { OnboardingClientInfo, OnboardingSessionInfo } from '../../../backend/models/Data';
import moment from 'moment-timezone';

const UpcomingSession = () => {
  const navigate = useNavigate();
  const [nextSession, setNextSession] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState('');
  const trainerID = sessionStorage.getItem('trainerID');

  useEffect(() => {
    const fetchUpcomingSessions = async () => {
      try {
        const sessions = await myApi.getUpcomingSessionsByTrainer(base, trainerID!);
        if (Array.isArray(sessions) && sessions.length > 0) {
          const session = sessions[0] as OnboardingSessionInfo;
          if (!session.clientID) {
            throw new Error('Session missing client ID');
          }

          const clientResponse = await myApi.readSingleClient(base, session.clientID);

          if (typeof clientResponse === 'object' && clientResponse !== null && 'firstName' in clientResponse) {
            const clientData = clientResponse as OnboardingClientInfo;
            const clientName = `${clientData.firstName} ${clientData.lastName?.charAt(0) || ''}.`;

            setNextSession({
              ...session,
              clientName,
            });
          }
        }
        setIsLoading(false);
      } catch (error: any) {
        console.error('Error fetching sessions:', error);
        setShowAlert(`Error fetching sessions:${error.message}`);
        setIsLoading(false);
      }
    };

    if (trainerID) {
      fetchUpcomingSessions();
      const interval = setInterval(fetchUpcomingSessions, 60000);
      return () => clearInterval(interval);
    }
  }, [trainerID]);

  const handleSessionClick = () => {
    if (!nextSession) return; // Don't handle click if no session exists

    const sessionTime = moment(nextSession.dateTime, 'YYYY-MM-DD hh:mm A', 'America/New_York');
    const currentTime = moment().tz('America/New_York');

    // Calculate time windows
    const earliestJoinTime = moment(sessionTime).subtract(5, 'minutes');
    const latestJoinTime = moment(sessionTime).add(10, 'minutes');

    if (currentTime.isBefore(earliestJoinTime)) {
      setShowAlert('It is too early to join this session. Please join within 5 minutes of the scheduled time.');
      return;
    } else if (currentTime.isAfter(latestJoinTime)) {
      setShowAlert('This session has expired. You can only join within 10 minutes after the scheduled time.');
      return;
    } else {
      sessionStorage.setItem('Agent', 'trainer');
      sessionStorage.setItem('clientID', nextSession.clientID);
      console.log('nextSession: ', nextSession);
      navigate(`/video-session/${nextSession.sessionID}`);
    }
  };

  if (isLoading) return null;

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <SessionContainer>
        <Typography
          sx={{
            color: '#F27C22',
            width: '87%',
            textAlign: 'left',
            mb: 1,
            fontFamily: 'arial',
            fontSize: '1rem',
            paddingLeft: 0,
            marginLeft: 0,
          }}
        >
          Upcoming Pre-scheduled Sessions:
        </Typography>
      </SessionContainer>
      <Collapse in={!!showAlert}>
        <Alert
          severity='error'
          sx={{
            backgroundColor: '#f8d7da',
            color: '#721c24',
            mb: 0.5,
          }}
        >
          {showAlert}
          <IconButton
            color='inherit'
            size='small'
            onClick={() => {
              console.log('Close button clicked');
              setShowAlert('');
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        </Alert>
      </Collapse>
      {nextSession ? (
        <Box sx={{ maxWidth: '90%', mx: 'auto' }}>
          <SessionContainer>
            <SessionBox
              style={{
                backgroundColor: '#D9D9D9',
                flex: 1,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'left',
                position: 'relative',
              }}
              onClick={handleSessionClick}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: '16px',
                  top: '15%',
                  bottom: '15%',
                  width: '3px',
                  backgroundColor: 'black',
                }}
              />
              <Typography
                variant='subtitle1'
                sx={{ fontWeight: '600', fontFamily: 'Inter, sans-serif', fontSize: '1.1rem' }}
              >
                {`${nextSession.sessionType.split(' ')[0]} minute Session with ${nextSession.clientName}`}
              </Typography>
              <Typography
                variant='body2'
                sx={{ color: '#8B8787', fontWeight: '600', fontFamily: 'Inter, sans-serif', fontSize: '0.9rem' }}
              >
                Online at{' '}
                {moment(nextSession.dateTime, 'YYYY-MM-DD hh:mm A', 'America/New_York')
                  .local()
                  .tz(moment.tz.guess())
                  .format('hh:mm A z')}
              </Typography>
            </SessionBox>
          </SessionContainer>
        </Box>
      ) : (
        <SessionBox>
          <Typography
            variant='subtitle1'
            sx={{ fontFamily: 'Inter, sans-serif', color: '#8B8787', textAlign: 'center' }}
          >
            No upcoming pre-scheduled sessions
          </Typography>
        </SessionBox>
      )}
    </Box>
  );
};

export const TrainerHomePage = () => {
  const trainerID = useSelector((state: RootState) => state.user.uid);
  const navigate = useNavigate();

  if (!sessionStorage.getItem('isOnline')) {
    sessionStorage.setItem('isOnline', JSON.stringify(false));
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const [isDeclined, setIsDeclined] = useState(false);
  const [isOnline, setIsOnline] = useState(sessionStorage.getItem('isOnline') === 'true' ? true : false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [retryTimeout, setRetryTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (trainerID) {
      sessionStorage.setItem('trainerID', trainerID);
    }
  }, [trainerID]);

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (isDeclined) {
      navigate('/trainer-accept');
    }
  }, [isDeclined, navigate]);

  useEffect(() => {
    sessionStorage.setItem('isOnline', JSON.stringify(isOnline));
  }, [isOnline]);

  useEffect(() => {
    const decline = sessionStorage.getItem('decline');

    if (decline === 'true') {
      sessionStorage.removeItem('decline');
      const session: OnboardingSessionInfo = {
        trainerID: trainerID!,
      };

      handleSessionRequest(session);
    }
  }, [trainerID]);

  const handleSessionRequest = async (session: OnboardingSessionInfo) => {
    const retryDelay = 30000;

    const attemptRequest = async () => {
      const res: FirebaseApiResponse = await myApi.setTrainerSession(base, session);

      if (typeof res === 'string' || (res as any).error) {
        console.log('Error: ', res);
        setAlertMessage('There are no suitable training sessions at the moment. This might take 10-20 minutes.');
        const alertTimeout = setTimeout(() => {
          setAlertOpen(true);
        }, 10000);
        const timeout = setTimeout(() => {
          attemptRequest();
        }, retryDelay);
        setRetryTimeout(timeout);

        return () => {
          clearTimeout(timeout);
          clearTimeout(alertTimeout);
        };
      } else {
        console.log(res);
        let x: any = JSON.stringify(res);
        sessionStorage.setItem('sessionID', x.sessionId);
        sessionStorage.setItem('sessionResData', JSON.stringify(res));
        await delay(2000);
        setIsDeclined(true);
      }
    };

    attemptRequest();
  };

  const whenOnline = () => {
    setIsOnline(true);
    const session: OnboardingSessionInfo = {
      trainerID: trainerID!,
    };
    handleSessionRequest(session);
  };

  const stopShift = () => {
    setIsOnline(false);
    setAlertOpen(false);

    if (retryTimeout) {
      clearTimeout(retryTimeout);
    }
  };

  return (
    <>
      <Appbar showMenu={false} userRole='trainer' />
      <ParentContainer>
        <Customcard>
          <Typography variant='h1' style={h1Style}>
            {isOnline ? "You're Online!" : 'Hey! Ready to start earning?'}
          </Typography>
          {isOnline ? (
            <ButtonContainer>
              <StateChangeButton
                variant='contained'
                style={{ backgroundColor: '#FF00008F', color: '#DEDEDE' }}
                onClick={stopShift}
                endIcon={<BackHandIcon />}
              >
                <span style={{ flex: 1, marginLeft: 16, textAlign: 'center' }}>Stop My Shift!</span>
              </StateChangeButton>
              <Typography
                style={{ fontFamily: 'Oswald, sans-serif', marginTop: '1rem', color: '#FFFFFF', fontSize: '1rem' }}
              >
                Stay tuned and wait to receive training session requests.
              </Typography>
              <UpcomingSession />
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <StateChangeButton
                variant='contained'
                style={{ backgroundColor: '#148C3D', color: '#DEDEDE' }}
                onClick={whenOnline}
                endIcon={<ArrowForwardIosIcon />}
              >
                <span
                  style={{
                    flex: 1,
                    marginLeft: 16,
                    fontFamily: 'sans-serif',
                    fontWeight: '700',
                    textAlign: 'center',
                  }}
                >
                  Begin My Shift!
                </span>
              </StateChangeButton>
              <UpcomingSession />
              <InfoButtonsContainer>
                <InfoButton
                  variant='contained'
                  style={{
                    backgroundColor: '#D9D9D9',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => navigate('/trainer-payout')}
                  startIcon={
                    <img
                      src='/moneybag_black.png'
                      alt='CloseRoundedIcon'
                      style={{ cursor: 'pointer', width: '30px', height: '30px', color: 'black' }}
                    />
                  }
                >
                  Show me my earnings
                </InfoButton>
                <InfoButton
                  variant='contained'
                  style={{
                    backgroundColor: '#D9D9D9',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleExternalLink('https://gyfrapp.com/pages/personal-trainer-guide')}
                  startIcon={<QuestionMarkIcon style={{ fontSize: '2rem' }} />}
                >
                  How does it work?
                </InfoButton>
              </InfoButtonsContainer>
            </ButtonContainer>
          )}

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'black',
}));

const SessionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
}));

const InfoButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '90%',
  gap: '1rem',
}));

const StateChangeButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem 1rem',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '1.2rem',
  textAlign: 'center',
  borderRadius: '15px',
  width: '90%',
  marginBottom: '0.5rem',
  textTransform: 'none',
  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
  },
  '& .MuiButton-startIcon': {
    marginRight: 'auto',
  },
}));

const InfoButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1rem',
  lineHeight: '1rem',
  fontFamily: 'Inter, sans-serif',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  marginTop: '1rem',
  width: '90%',
  flex: 1,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));

const SessionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1rem',
  lineHeight: '1rem',
  fontFamily: 'Inter, sans-serif',
  alignItems: 'flex-start', // Change here to 'flex-start'
  justifyContent: 'flex-start', // Push content to the right
  padding: '0.5rem 2rem', // Increase padding-left
  marginLeft: '0.9rem',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  marginTop: '1rem',
  width: '80%',
  margin: '0 auto',
  flex: 1,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});

const h1Style: CSSProperties = {
  font: 'roboto',
  color: '#F27C22',
  fontSize: '1.3rem',
  fontWeight: '700',
  marginBottom: '1rem',
  fontFamily: 'Inter',
  textAlign: 'center',
  padding: '0.5rem 1rem',
  borderRadius: '10px',
  marginTop: '0.5rem',
  textTransform: 'none',
};

export default TrainerHomePage;
